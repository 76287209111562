.main-button {
    background-color: var(--link-color);
    border: solid 1px var(--link-color);
    color: #fff;
    font-weight: 700;
    width: max-content;
    padding: 2px 22px;
    margin-right: 50px;
    text-transform: uppercase;
    border-radius: 6px;
    text-align: center;
    text-decoration: none;
    display: block;
    /* margin-top: 20px; */
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  
  .main-button:hover {
    background-color: var(--darkBoxShadow2);
    color: var(--link-color);
    transition: all 0.3s ease;
    transform: translateY(-3px);
  }


  /* Media Queries */
  @media (max-width: 768px) {
    .main-button {
      font-size: 0.938rem;
      padding: 2px 18px;
      margin-right: 0;
    }
  }
  
  @media (max-width: 320px) {
    .main-button {
      font-size: 0.75rem;
    }
  }